if (document.querySelector('.cookie-validate')) {
	document.querySelector('.cookie-validate').addEventListener('click', function (e) {
		e.preventDefault();

		Cookies.set('gdpr-validated', true);
		document.querySelector('.cookie-popin').classList.add('close');
		
		jQuery.get( PATH_TO_THEME + '/partials/analytics.php', function(analytics){
			$('body').append(analytics);
		}, 'html');
	});
}