var baseURL = "/sites/all/themes/maredsousfromages/";

var loadModal = function(url){
	$('body').prepend('<div class="load-bar"><div class="bar"></div><div class="bar"></div></div>');
	$.get(url, function(html){
		
			html = $(html).find('.container').html();
		
			$('.product-modal-container').remove();
			$('.load-bar').remove();
			
			$('body').append('<div class="product-modal-container" style="display:none;" ><div class="content"><span class="close" onclick="$(\'.product-modal-container\').fadeOut(function(){$(this).remove()});" >&times;</span>' + html + '</div></div>');
			$('.product-modal-container').fadeIn();			
		});
};

$(document).ready(function(){
	/*$('a.product-modal').click(function(){
		
		var href = $(this).attr('href');
		var queryString = href.split('?').pop();
		
		var params = queryString.split('=');
		var lang = $('html').attr('lang');
		var ajaxURL = baseURL + '/products/' + lang + '/' + params[1] + '.php';
		
		loadModal(href);
		return false;

	});*/
	
	if( $('.thanks').length > 0 ) {
		fbq('track', 'CompleteRegistration', { 
			status: $('html').attr('lang'),
			content_name: window.location.href
		});
	}
});