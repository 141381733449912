

var timeRight = {
	origin: 'right',
	delay    : 200,
	distance : '90px',
	easing   : 'ease-in-out',
	rotate   : { z: 10 },
	scale    : 1,
	viewFactor: 0.4,
	reset: true
};

var timeLeft = {
	origin: 'left',
	duration: 600,
	delay    : 120,
	distance : '110px',
	easing   : 'ease-in',
	rotate: { x: 0, y: 0, z: 5 },
	scale    : 1,
	viewFactor: 0.4,
	reset: true 
};

var timeUp = {
	origin: 'top',
	delay    : 220,
	duration: 300,
	distance : '120px',
	easing   : 'ease',
	rotate: { x: 0, y: 5, z: 0 },
	scale    : 1,
	viewFactor: 0.4,
	reset: true 
};

var timeDown = {
	origin: 'bottom',
	delay    : 220,
	duration: 500,
	distance : '100px',
	easing   : 'ease',
	rotate: { x: 10, y: 5, z: 0 },
	scale    : 1,
	viewFactor: 0.4,
	reset: true 
};

window.sr = ScrollReveal();
sr.reveal('.timeRight', timeRight);
sr.reveal('.timeLeft', timeLeft);
sr.reveal('.timeUp', timeUp);
sr.reveal('.timeBottom', timeDown);
sr.reveal('.reveal');


// RECETTE JS

// $('.mosaic_recettes .mosaic_block').click(function(){
//     $('.mosaic_recettes .mosaic_block').removeClass("open");
//     $(this).toggleClass("open");
// });

//UL to OL COLOR

$(document).ready( function() {
	$('section.inner div.container ul:last-of-type li, section.inner div.container ol li').wrapInner('<span> </span>').addClass('');
	$('.inspiration').click(function(){
		ga('send', 'pageview', '/' + $('html').attr('lang') + '/inspiration' );
	});
});

// BURGER MENU

var clickDelay = 500,
	clickDelayTimer = null;

$('.burger-click-region').on('click', function () {
	
	if(clickDelayTimer === null) {
	
		var $burger = $(this);
		$burger.toggleClass('active');
		$burger.parent().toggleClass('is-open');
		$('body').toggleClass('is-open');

		if(!$burger.hasClass('active')) {
			$burger.addClass('closing');
		}

		clickDelayTimer = setTimeout(function () {
			$burger.removeClass('closing');
			clearTimeout(clickDelayTimer);
			clickDelayTimer = null;
		}, clickDelay);
	
	ga('send', 'event', 'menu', 'click', 'open');
	}
});

var validateEmail = function(email) {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
};

// SCROLL TO #ID

$(function() { 
	$('a[href*="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top - $('header').height()
				}, 1500);
				return false;
			}
		}
	});
});

// SLIDER AUTO LAUNCH TIMER

var AnimationLauncher = function(){
	$(".cd-resize-img").addClass("animation-1");
	$(".cd-handle").addClass("animation-2");
	
	setTimeout(function(){
		$(".title").addClass("animation-3");
	}, 10);
};

var userHasDragged = false;
$(window).load(function(){
	setTimeout(function(){
		if(!userHasDragged) {
			AnimationLauncher();
		}
	}, 500);
});

// CTA POP UP

$('.cta-block a.close').click(function(){
	if( $('.cta-block .container').hasClass('stored') ) {
		$('.cta-block .container').removeClass("moved");
	} else {
		$('.cta-block').toggleClass("nope");
	}
});


//CHEESELETTER POPUP

$('a[data-call="cheeseletter-subscribe"]').click(function () {
	$('#cheeseletter-box').fadeIn(300);
});
$('#cheeseletter-box .close-cta-form').click(function (e) {
	e.preventDefault();
	$('#cheeseletter-box').fadeOut(300);
});

/*$('section.cheeseletter .cta').click(function(){
	$('div.cta-form').fadeIn(300);
});*/

/*$('section.cheeseletter .close-cta-form').click(function(){
	$('div.cta-form').fadeOut(300);
});*/

// MOSAIC HEIGHT GENERATOR

$(window).on( 'resize', function () { 
	if($(window).width() > 720) {
		$('.mosaic .mosaic_block').height( $('.mosaic .mosaic_block').width());
		$('.selector-holder .selector').height( $('.selector-holder .height-js').height());
	}
}).resize();

// RESIZE CALCULATOR

var resizeCaptionImages = function(){
	return $('.adapt-size').each(function(){
		$(this).height( $(this).parents('.container-block').height() );      
	});
};

var adaptHeights = function(){
	var max = 0;
	$('.adapt-heights').each(function(){
		max = Math.max( $(this).height());
	});
	$('.adapt-heights').height(max);
};

$(document).ready(resizeCaptionImages);
$(window).resize(resizeCaptionImages);
$(window).load(resizeCaptionImages);

$(document).ready(adaptHeights);
$(window).resize(adaptHeights);
$(window).load(adaptHeights);

// BACKGROUND-ATTACHMENT FIXED WHEN CONTAINER HEIGHT > 1000

if ($('section.inner .container').height() > 1000) {
	$('section.inner').css('background-attachment', 'fixed');
}


//SLIDER 

jQuery(document).ready(function($){
	
	if( $.cookie('cheeseletter') ) {
		$('div.cta-block div.container').addClass('stored');
	} else {
		$('div.cta-block div.container').addClass('show');
		$.cookie('cheeseletter', true);
	}	
	
	$('.cta-block a.stored').click( function(){
		$(this).parent().toggleClass('moved');
	});
	
	$('a.close').click( function(){
		$('.modal').fadeOut(function(){
			$(this).remove();
		});
	} );
	
	$('form.cheeseletter_form input').focus( function(){
		$(this).removeClass('error-warning');
	});
	
	$('form.cheeseletter_form').submit(function(){
		var error = false;
		
		$(this).find('input[name=email]').each(function(){
			if( !validateEmail($(this).val())) {
				$(this).addClass('error-warning');
				error = true;	
			}
		});
		
		return !error;
	});
	
	var dragging = false,
		scrolling = false,
		resizing = false;
	//cache jQuery objects
	var imageComparisonContainers = $('.cd-image-container');
	//check if the .cd-image-container is in the viewport 
	//if yes, animate it
	checkPosition(imageComparisonContainers);
	$(window).on('scroll', function(){
		if( !scrolling) {
			scrolling =  true;
			( !window.requestAnimationFrame ) ? setTimeout(function(){checkPosition(imageComparisonContainers);}, 100) : requestAnimationFrame(function(){checkPosition(imageComparisonContainers);});
		}
	});
	
	//make the .cd-handle element draggable and modify .cd-resize-img width according to its position
	imageComparisonContainers.each(function(){
		var actual = $(this);
		drags(actual.find('.cd-handle'), actual.find('.cd-resize-img'), actual, actual.find('.cd-image-label[data-type="original"]'), actual.find('.cd-image-label[data-type="modified"]'));
	});

	//upadate images label visibility
	$(window).on('resize', function(){
		if( !resizing) {
			resizing =  true;
			( !window.requestAnimationFrame ) ? setTimeout(function(){checkLabel(imageComparisonContainers);}, 100) : requestAnimationFrame(function(){checkLabel(imageComparisonContainers);});
		}
	});

	function checkPosition(container) {
		container.each(function(){
			var actualContainer = $(this);
			if( $(window).scrollTop() + $(window).height()*0.5 > actualContainer.offset().top) {
					actualContainer.addClass('is-visible');
			}
		});

		scrolling = false;
	}

	function checkLabel(container) {
		container.each(function(){
			var actual = $(this);
			updateLabel(actual.find('.cd-image-label[data-type="modified"]'), actual.find('.cd-resize-img'), 'left');
			updateLabel(actual.find('.cd-image-label[data-type="original"]'), actual.find('.cd-resize-img'), 'right');
		});

		resizing = false;
	}

	//draggable funtionality - credits to http://css-tricks.com/snippets/jquery/draggable-without-jquery-ui/
	function drags(dragElement, resizeElement, container, labelContainer, labelResizeElement) {
		dragElement.on("mousedown vmousedown", function(e) {
			
			if(!userHasDragged) $(".title").css("opacity", 1);
			userHasDragged = true;
			
				dragElement.addClass('draggable');
				resizeElement.addClass('resizable');

				var dragWidth = dragElement.outerWidth(),
					xPosition = dragElement.offset().left + dragWidth - e.pageX,
					containerOffset = container.offset().left,
					containerWidth = container.outerWidth(),
					minLeft = containerOffset + 10,
					maxLeft = containerOffset + containerWidth - dragWidth - 10;
				
				dragElement.parents().on("mousemove vmousemove", function(e) {
					if( !dragging) {
						dragging =  true;
						( !window.requestAnimationFrame )
							? setTimeout(function(){animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement, labelContainer, labelResizeElement);}, 100)
							: requestAnimationFrame(function(){animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement, labelContainer, labelResizeElement);});
					}
				}).on("mouseup vmouseup", function(e){
					dragElement.removeClass('draggable');
					resizeElement.removeClass('resizable');
				});
				e.preventDefault();
		}).on("mouseup vmouseup", function(e) {
			dragElement.removeClass('draggable');
			resizeElement.removeClass('resizable');
		});
	}

	function animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement, labelContainer, labelResizeElement) {
		var leftValue = e.pageX + xPosition - dragWidth;   
		//constrain the draggable element to move inside his container
		if(leftValue < minLeft ) {
				leftValue = minLeft;
		} else if ( leftValue > maxLeft) {
				leftValue = maxLeft;
		}

		var widthValue = (leftValue + dragWidth/2 - containerOffset)*100/containerWidth+'%';
		
		$('.draggable').css('left', widthValue).on("mouseup vmouseup", function() {
				$(this).removeClass('draggable');
				resizeElement.removeClass('resizable');
		});

		$('.resizable').css('width', widthValue); 

		updateLabel(labelResizeElement, resizeElement, 'left');
		updateLabel(labelContainer, resizeElement, 'right');
		dragging =  false;
	}

	function updateLabel(label, resizeElement, position) {
		if(position == 'left') {
			( label.offset().left + label.outerWidth() < resizeElement.offset().left + resizeElement.outerWidth() ) ? label.removeClass('is-hidden') : label.addClass('is-hidden') ;
		} else {
			( label.offset().left > resizeElement.offset().left + resizeElement.outerWidth() ) ? label.removeClass('is-hidden') : label.addClass('is-hidden') ;
		}
	}
});

// TIMELINE

$(document).ready(function(){
	$('div.block-timer ul li').hover(
		function(){
			$(this).prev().addClass('previous');
			$(this).addClass('current');
			$(this).next().addClass('next');
		},
		function(){
			$('div.block-timer ul li').attr('class', '');
		}
	);
});