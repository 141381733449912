$(document).ready( function(){

	$('.mosaic_recettes .selector .filled-in').change(function(){
	
		if( $(this).hasClass('all') ) {
			
			$('input:checked:not(.all)').prop('checked', false);
			if( $(this).prop('checked') ) {			
				$('.mosaic_block').parent().removeClass('hide');		
			} else {
				$('.mosaic_block').parent().addClass('hide');
			}
			
		} else {
			
			$('input.all').prop('checked', false);		
			$('.mosaic_block').parent().addClass('hide');
			
			var checkedElements = $('.mosaic_recettes .selector .filled-in:checked');
			checkedElements.each(function(){
				var filter = $(this).data('filter');
				$('.mosaic_block.' + filter).parent().removeClass('hide');
			});
			
			if( checkedElements.length === 0 ) {
				$('.mosaic_block').parent().removeClass('hide');	
				$('input.all').prop('checked', true);
			}
		}
		 	
	});

	var search = $(location).attr('search').substring(1);
	$('input[data-filter="'+ search +'"]').trigger('click');

	function lookup (e) {
		var search = $(e).val();

		$('.mosaic_block').parent().removeClass('filtered');
		$('.mosaic_block').each( function () {
			if($(this).css('display') === 'block') {
				var txt = $(this).find('.mosaic_infos h3').text();
				if( txt.toLowerCase().indexOf( search.toLowerCase() ) < 0 ) {
					$(this).parent().addClass('filtered');
				}
			}
		});
	}

	$('.search input').keyup(function(){ lookup(this) });
	if ($('.search input').length > 0 && $('.search input').val().length > 0)
		lookup($('.search input'));
});

